import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import userStore from '../../stores/UserStore';
import tetherApi from '../../api/tether';
import ReactDatePicker from 'react-datepicker';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Confirm from '../Confirm';
import { capitalizeFirstLetter } from '../../utils/stringUtils';
import _ from 'lodash';
import { statusPill } from '../../utils/pillHelper';
import { statusLabels } from '../../utils/humanReadableLabels';

// import { CheckIcon } from '@heroicons/react/24/outline';

function OrgHabitForm() {
	const {
		register,
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({
		defaultValues: {
			frequency: 'daily',
			// accessRole: null,
		},
	});

	const { orgHabitId } = useParams();
	const [orgHabit, setOrgHabit] = useState(null);
	const [orgHabitTypes, setOrgHabitTypes] = useState([]);
	const [practices, setPractices] = useState(null);
	const [authors, setAuthors] = useState([]);
	const [fetchingPractices, setFetchingPractices] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [selectedFrequency, setSelectedFrequency] = useState('daily');
	const [selectedDaysOfMonth, setSelectedDaysOfMonth] = useState([]);
	const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState([]);
	const [selectedTimesPerDay, setSelectedTimesPerDay] = useState(1);
	const [selectedTimes, setSelectedTimes] = useState([]);
	const [allDay, setAllDay] = useState(false);
	const [autoAssignToUsers, setAutoAssignToUsers] = useState(false);
	const [isPreset, setIsPreset] = useState(false);
	const [selectedColor, setSelectedColor] = useState(null);
	const [unmonitoredFieldChanged, setUnmonitoredFieldChanged] = useState(false);

	let navigate = useNavigate();

	const watchStartDate = watch('startDate');
	const watchEndDate = watch('endDate');

	useEffect(() => {
		const asyncSetup = async () => {
			if (!orgHabitTypes.length) {
				await fetchOrgHabitTypes();
			}

			if (!practices && !fetchingPractices) {
				setFetchingPractices(true);

				await fetchPractices();
			}

			if (!authors.length) {
				await fetchAuthors();
			}

			if (orgHabitId && !orgHabit && orgsStore.orgs.length) {
				fetchOrgHabit(orgHabitId);
			}
			setSuccessMessage(orgHabitId ? 'Habit updated!' : 'Habit created!');
		};
		asyncSetup();
	}, [orgHabit, orgHabitTypes, practices, authors, orgsStore.currentOrg, deleteConfirm]);
	// let tier = orgsStore.getOrgTier();

	const fetchOrgHabitTypes = async () => {
		const orgHabitTypesRresponse = await tetherApi.getPracticeTypes();

		setOrgHabitTypes(orgHabitTypesRresponse.data);
	};

	const fetchPractices = async () => {
		const practicesResponse = await tetherApi.getPractices(1, 100, true);

		setPractices(practicesResponse.data);
	};

	const fetchAuthors = async () => {
		const authorsResponse = await tetherApi.getAuthors();

		setAuthors(authorsResponse.data);
	};

	const fetchOrgHabit = async (orgHabitId) => {
		const orgHabitResponse = await tetherApi.getOrgHabit(orgHabitId);
		if (orgHabitResponse.authorId) {
			setValue('authorId', orgHabitResponse.authorId);
		}

		setOrgHabit(orgHabitResponse.data);

		reset(orgHabitResponse.data);
		setAllDay(orgHabitResponse.data.allDay);
		setAutoAssignToUsers(orgHabitResponse.data.autoAssignToUsers);

		setIsPreset(orgHabitResponse.data.isPreset);
		setSelectedFrequency(orgHabitResponse.data.frequency);

		if (orgHabitResponse.data.frequency !== 'daily') {
			if (orgHabitResponse.data.frequency !== 'monthly') {
				setSelectedDaysOfWeek(orgHabitResponse.data.frequencyOn);
			} else {
				const dates = orgHabitResponse.data.frequencyOn.map((date) => {
					const dt = new Date();
					dt.setDate(date);
					return dt;
				});
				setSelectedDaysOfMonth(dates);
			}
		}

		setSelectedColor(orgHabitResponse.data.hexColor);

		if (orgHabitResponse.data.frequencyAt) {
			setSelectedTimes([
				{
					timeNumber: 1,
					selectedTime: moment(orgHabitResponse.data.frequencyAt).utc().format('HH:mm'),
				},
			]);
		}
	};

	const onSubmit = async (data) => {
		const result = await submitOrgHabit(data);
		if (result) {
			alertService.success(successMessage);

			navigate('/habits');
		}
	};

	const submitHabit = async (data, frequencyAt) => {
		const newHabitData = {
			title: data.title,
			durationInMinutes: data.durationInMinutes,
			frequency: data.frequency,
			startDate: data.startDate,
			status: data.status,
			isPreset: isPreset,
			allDay,
		};
		if (frequencyAt) {
			newHabitData.frequencyAt = frequencyAt;
		}
		if (data.description) {
			newHabitData.description = data.description;
		}
		if (data.habitType) {
			newHabitData.habitType = data.habitType;
		}
		if (selectedFrequency !== 'daily') {
			if (selectedFrequency === 'monthly') {
				newHabitData.frequencyOn = selectedDaysOfMonth.map((day) => day.day);
			} else {
				newHabitData.frequencyOn = selectedDaysOfWeek;
			}
		}
		if (data.endDate) {
			newHabitData.endDate = data.endDate;
		}
		if (data.practiceId) {
			newHabitData.practiceId = data.practiceId;
		}
		if (data.authorId) {
			newHabitData.authorId = data.authorId;
		}
		if (autoAssignToUsers) {
			newHabitData.autoAssignToUsers = autoAssignToUsers;
		}
		if (selectedColor) {
			newHabitData.hexColor = selectedColor;
		}
		if (data.image[0]) {
			newHabitData.image = data.image[0];
		}
		if (data.secondaryImage[0]) {
			newHabitData.secondaryImage = data.secondaryImage[0];
		}

		newHabitData.isFeatured = data.isFeatured;
		newHabitData.isRecommended = data.isRecommended;

		const newHabitFormData = new FormData();
		Object.keys(newHabitData).map((key) => {
			newHabitFormData.append(key, newHabitData[key]);
		});
		newHabitFormData.append('isMembersOnly', data.isMembersOnly);
		if (data.id) {
			return await tetherApi.updateOrgHabit(data.id, newHabitFormData);
		} else {
			return await tetherApi.createOrgHabit(newHabitFormData);
		}
	};

	const submitOrgHabit = async (data) => {
		try {
			if (allDay) {
				submitHabit(data);
			} else {
				for await (const selectedTime of selectedTimes) {
					submitHabit(data, selectedTime.selectedTime);
				}
			}
			return true;
		} catch {
			return false;
		}
	};

	const deleteOrgHabit = async () => {
		const result = await tetherApi.deleteOrgHabit(orgHabitId);

		if (result) {
			alertService.success('Habit deleted');

			navigate('/habits');
		}
	};

	const handleDayPress = (day) => {
		if (selectedDaysOfWeek.includes(day)) {
			setSelectedDaysOfWeek(selectedDaysOfWeek.filter((d) => d !== day));
		} else {
			setSelectedDaysOfWeek([...selectedDaysOfWeek, day]);
		}
	};

	const renderAllDay = () => {
		return (
			<di className="col-span-full">
				<div className="relative flex gap-x-3">
					<div className="flex h-6 items-center">
						<input
							disabled={userStore.user?.isSuperUser ? '' : 'disabled'}
							checked={allDay}
							onChange={() => {
								setAllDay(!allDay);
								setUnmonitoredFieldChanged(true);
							}}
							type="checkbox"
							className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
						/>
					</div>
					<div className="text-sm leading-6">
						<label htmlFor="candidates" className="font-medium text-gray-900">
							All Day
						</label>
						<p className="text-gray-500">Assigns the habit all day</p>
					</div>
				</div>
			</di>
		);
	};

	const renderAutoAssign = () => {
		return (
			<div className="relative flex gap-x-3">
				<div className="flex h-6 items-center">
					<input
						disabled={userStore.user?.isSuperUser ? '' : 'disabled'}
						checked={autoAssignToUsers}
						onChange={() => {
							setAutoAssignToUsers(!autoAssignToUsers);
							setUnmonitoredFieldChanged(true);
						}}
						type="checkbox"
						className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
					/>
				</div>
				<div className="text-sm leading-6">
					<label htmlFor="candidates" className="font-medium text-gray-900">
						All Members
					</label>
					<p className="text-gray-500">
						Assigns the Habit to all existing and future members in your organization
						<br />
						Please contact support to enable!
					</p>
				</div>
			</div>
		);
	};

	const renderIsPreset = () => {
		return (
			<div className="relative flex gap-x-3">
				<div className="flex h-6 items-center">
					<input
						checked={isPreset}
						onChange={() => {
							setIsPreset(!isPreset);
							setUnmonitoredFieldChanged(true);
						}}
						type="checkbox"
						className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
					/>
				</div>
				<div className="text-sm leading-6">
					<label htmlFor="candidates" className="font-medium text-gray-900">
						Preset
					</label>
					<p className="text-gray-500">Displays the Habit as one of the global presets in Tether</p>
				</div>
			</div>
		);
	};

	const renderIsRecommended = () => {
		if (userStore.user.isSuperUser === true) {
			return (
				<div className="col-span-full">
					<div className="relative flex gap-x-3">
						<div className="flex h-6 items-center">
							<input
								{...register('isRecommended')}
								type="checkbox"
								className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
							/>
						</div>
						<div className="text-sm leading-6">
							<label htmlFor="candidates" className="font-medium text-gray-900">
								Featured Preset
							</label>
							<p className="text-gray-500">
								Select if you want to feature the habit on the preset screen
							</p>
						</div>
					</div>
				</div>
			);
		}
	};

	const renderIsPublic = () => {
		if (orgsStore.currentOrg?.isPublisher === true) {
			return (
				<div className="relative flex gap-x-3">
					<div className="flex h-6 items-center">
						<input
							{...register('isPublic')}
							type="checkbox"
							className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
						/>
					</div>
					<div className="text-sm leading-6">
						<label htmlFor="candidates" className="font-medium text-gray-900">
							Public
						</label>
						<p className="text-gray-500">Publish to global content library</p>
					</div>
				</div>
			);
		}
	};

	const renderTimesPerDay = () => {
		if (!allDay && !orgHabitId) {
			return (
				<div className="col-span-full">
					<label htmlFor="timesPerDay" className="block text-sm font-medium leading-6 text-gray-900">
						Times Per Day
					</label>
					<div className="mt-2">
						<select
							disabled={orgHabit?.groupOrgHabitsExist}
							className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
							onChange={(event) => {
								setSelectedTimesPerDay(event.target.value);
								setUnmonitoredFieldChanged(true);
							}}
						>
							<option key="frequency-daily" value="1">
								1
							</option>
							<option key="frequency-weekday" value="2">
								2
							</option>
							<option key="frequency-weekly" value="3">
								3
							</option>
							<option key="frequency-biweekly" value="4">
								4
							</option>
							<option key="frequency-monthly" value="5">
								5
							</option>
						</select>
						<p className="mt-2 text-sm text-gray-500  font-regular">
							This will create a separate habit for each time of day
						</p>
					</div>
				</div>
			);
		}
	};

	const renderAtTimes = () => {
		if (!allDay) {
			const atTimes = [];
			for (let i = 0; i < selectedTimesPerDay; i++) {
				const timeNumber = i + 1;
				const selectedTime = selectedTimes.find((selectedTime) => selectedTime.timeNumber === timeNumber);

				atTimes.push(
					<div className="col-span-full">
						<label htmlFor="frequencyAt" className="block text-sm font-medium leading-6 text-gray-900">
							{`${selectedTimesPerDay > 1 ? `${timeNumber} - ` : ''}At Time*`}
						</label>
						<div className="mt-2">
							<input
								type="text"
								placeholder="E.g. 14:30"
								className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
								onChange={(event) => {
									const newText = event.target.value;
									const selectedTimesClone = _.cloneDeep(selectedTimes);
									const timeIndex = selectedTimesClone.findIndex(
										(time) => time.timeNumber === timeNumber,
									);
									if (timeIndex >= 0) {
										selectedTimesClone[timeIndex].selectedTime = newText;
									} else {
										selectedTimesClone.push({
											selectedTime: newText,
											timeNumber,
										});
									}
									setSelectedTimes(selectedTimesClone);
									setUnmonitoredFieldChanged(true);
								}}
								value={selectedTime?.selectedTime ?? ''}
							/>
							<p className="mt-2 text-sm text-gray-500  font-regular">Use military time</p>
							<p
								className={errors.frequencyAt?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
								id="frequencyAt-error"
							>
								{errors.frequencyAt?.message}
							</p>
						</div>
					</div>,
				);
			}
			return atTimes;
		}
	};

	const renderFrequencyOn = () => {
		if (selectedFrequency === 'daily') {
			return;
		}
		if (selectedFrequency === 'monthly') {
			return (
				<div className="col-span-full">
					<label
						htmlFor="frequencyOn[]"
						className="block text-sm font-medium leading-6 text-gray-900 font-regular"
					>
						{`On Day${selectedDaysOfMonth.length > 1 ? 's' : ''}`}
					</label>
					<div className="mt-2 max-w-lg sm:max-w-xs">
						<DatePicker
							className="max-w-lg"
							containerClassName="w-full"
							inputClass="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md w-lg w-full font-regular"
							multiple
							value={selectedDaysOfMonth}
							onChange={(value) => {
								setSelectedDaysOfMonth(value);
								setUnmonitoredFieldChanged(true);
							}}
							plugins={[<DatePanel key="selected-dates-panel" />]}
						/>
						<p
							className={
								errors.frequencyOn?.message ? 'mt-2 text-sm text-red-600 font-regular' : 'hidden'
							}
							id="frequencyOn-error"
						>
							{errors.frequencyOn?.message}
						</p>
					</div>
				</div>
			);
		}
		const renderDayButton = (value) => {
			const unselectedClassName =
				'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none mr-1 my-1';
			const selectedClassName =
				'disabled:opacity-25 mr-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-neutral-800 my-1';
			return (
				<button
					type="button"
					onClick={() => {
						handleDayPress(value);
						setUnmonitoredFieldChanged(true);
					}}
					className={selectedDaysOfWeek.includes(value) ? selectedClassName : unselectedClassName}
				>
					{capitalizeFirstLetter(value)}
				</button>
			);
		};
		return (
			<div className="col-span-full">
				<label htmlFor="frequencyOn[]" className="block text-sm font-medium leading-6 text-gray-900">
					{`On Day${selectedDaysOfWeek.length > 1 ? 's' : ''}`}
				</label>
				<div className="mt-2">
					<div className="flex flex-row flex-wrap">
						{selectedFrequency !== 'weekday' && <div>{renderDayButton('sunday')}</div>}
						{renderDayButton('monday')}
						{renderDayButton('tuesday')}
						{renderDayButton('wednesday')}
						{renderDayButton('thursday')}
						{renderDayButton('friday')}
						{selectedFrequency !== 'weekday' && <div>{renderDayButton('saturday')}</div>}
					</div>
					<p
						className={errors.frequencyOn?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
						id="frequencyOn-error"
					>
						{errors.frequencyOn?.message}
					</p>
				</div>
			</div>
		);
	};

	return (
		<form className="p-12 space-y-8" id="org-habit-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-3xl font-semibold text-gray-900 ">{orgHabitId ? '' : 'New Habit'}</h3>
					</div>
					<div className="flex flex-row justify-between items-center w-full">
						<div>
							<span
								className={
									'inline-flex items-center rounded-md px-2.5 py-0.5 mb-2 text-sm font-medium ' +
									statusPill(orgHabit?.status)
								}
							>
								{statusLabels[orgHabit?.status] || ''}
							</span>
							<h3 className="text-3xl font-semibold text-gray-900 max-w-2xl truncate">
								{orgHabit?.title}
							</h3>
						</div>
						<div>
							{!!orgHabit?.webUrl && (
								<button
									type="button"
									onClick={() => navigator.clipboard.writeText(orgHabit?.webUrl)}
									className="cursor-pointer disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
								>
									Copy URL
								</button>
							)}
						</div>
					</div>
					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Access</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Select whether to publish or keep the content private and only access if you have the
								link.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
									Status*
								</label>
								<div className="mt-2">
									<select
										{...register('status', { required: 'Status is required' })}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="status-draft" value="draft">
											Draft
										</option>
										<option key="status-published" value="published">
											Published
										</option>
										<option key="status-published-private" value="published-private">
											Private
										</option>
									</select>
									<p
										className={errors.status?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="status-error"
									>
										{errors.status?.message}
									</p>
								</div>
							</div>
							{/* <div className="col-span-full">
								<label
									htmlFor="accessRole"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									User Role
								</label>
								<div className="mt-2">
									<select
										{...register('accessRole')}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md font-regular"
									>
										<option key="access-role-all" value="">
											All Users
										</option>
										<option key="access-role-org-owner" value="org_owner">
											Staff Admins
										</option>
										<option key="access-role-group-leader" value="group_leader">
											Group Admins
										</option>
									</select>
								</div>
							</div> */}
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the name, description, and author.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
									Name*
								</label>
								<div className="mt-2">
									<input
										{...register('title', {
											required: 'Title is required',
											maxLength: 'Title must be 255 characters or less',
										})}
										type="text"
										placeholder="The title of the habit"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.title?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="title-error"
									>
										{errors.title?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="description"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Description
								</label>
								<div className="mt-2">
									<textarea
										{...register('description')}
										rows={3}
										placeholder="An description of the habit"
										className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.description?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="description-error"
									>
										{errors.description?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="authorId" className="block text-sm font-medium leading-6 text-gray-900">
									Author*
								</label>
								<div className="mt-2">
									<select
										{...register('authorId', {
											required: 'Author is required',
										})}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="author-blank" value="">
											Select an Author...
										</option>
										{authors.map((author) => (
											<option key={`author-${author.id}`} value={author.id}>
												{author.name}
											</option>
										))}
									</select>
									<p
										className={errors.author?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="author-error"
									>
										{errors.author?.message}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Brand</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the brand details like image, category, and type.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="image" className="block text-sm font-medium leading-6 text-gray-900">
									Rectangle Image - (1800 x 1080)
								</label>
								<div className="mt-2">
									<input
										{...register('image')}
										type="file"
										name="image"
										id="image"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Accepts .png, .jpg, .jpeg
									</p>

									<div className={orgHabit?.imageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<img
											src={orgHabit?.imageUrl}
											alt=""
											className={orgHabit?.imageUrl ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="secondaryImage"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Square Image - (1080 x 1080)
								</label>
								<div className="mt-2">
									<input
										{...register('secondaryImage')}
										type="file"
										name="secondaryImage"
										id="secondaryImage"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Accepts .png, .jpg, .jpeg
									</p>

									<div className={orgHabit?.secondaryImageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<img
											src={orgHabit?.secondaryImageUrl}
											alt=""
											className={orgHabit?.secondaryImageUrl ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="habitType"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Category*
								</label>
								<div className="mt-2">
									<select
										{...register('habitType', {
											required: 'Category is required',
										})}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="habit-type-blank" value="">
											Select a Category...
										</option>
										{orgHabitTypes.map((habitType) => (
											<option key={`habitType-${habitType.key}`} value={habitType.key}>
												{habitType.label}
											</option>
										))}
									</select>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										This label organizes and describes the habit to users.
									</p>
									<p
										className={errors.habitType?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="habit-type-error"
									>
										{errors.habitType?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="practiceId"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Associated Practice
								</label>
								<div className="mt-2">
									<select
										{...register('practiceId')}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="step-type-blank" value="">
											Select a Practice...
										</option>
										{practices?.map((practice) => (
											<option key={`practice-${practice.id}`} value={practice.id}>
												{practice.title}
											</option>
										))}
									</select>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Cannot be changed after being published
									</p>
									<p
										className={errors.practiceId?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="practice-error"
									>
										{errors.practiceId?.message}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Schedule</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the schedule, time, and duration.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label
									htmlFor="startDate"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Start Date*
								</label>
								<div className="mt-2">
									<ReactDatePicker
										{...register('startDate', { required: 'Start Date is required' })}
										disabled={orgHabit?.groupOrgHabitsExist}
										dateFormat={'yyyy-MM-dd'}
										selected={watchStartDate ? moment(watchStartDate).toDate() : null}
										onChange={(date) => setValue('startDate', date)}
										calendarClassName="datepicker-calendar"
										type="text"
										autocomplete="off"
										className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Cannot be changed after being published
									</p>
									<p className="mt-2 text-sm text-red-600">{errors.startDate?.message}</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="endDate" className="block text-sm font-medium leading-6 text-gray-900">
									End Date
								</label>
								<div className="mt-2">
									<ReactDatePicker
										{...register('endDate')}
										disabled={orgHabit?.groupOrgHabitsExist}
										dateFormat={'yyyy-MM-dd'}
										selected={watchEndDate ? moment(watchEndDate).toDate() : null}
										onChange={(date) => setValue('endDate', date)}
										calendarClassName="datepicker-calendar"
										type="text"
										autocomplete="off"
										className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Cannot be changed after being published
									</p>
									<p className="mt-2 text-sm text-red-600">{errors.endDate?.message}</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="frequency"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Frequency*
								</label>
								<div className="mt-2">
									<select
										{...register('frequency', {
											required: 'Frequency is required',
										})}
										disabled={orgHabit?.groupOrgHabitsExist}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
										onChange={(event) => {
											setSelectedFrequency(event.target.value);
											setUnmonitoredFieldChanged(true);
										}}
										value={selectedFrequency}
									>
										<option key="frequency-daily" value="daily">
											Daily
										</option>
										<option key="frequency-weekday" value="weekday">
											Weekday
										</option>
										<option key="frequency-weekly" value="weekly">
											Weekly
										</option>
										<option key="frequency-biweekly" value="biweekly">
											Bi-Weekly
										</option>
										<option key="frequency-monthly" value="monthly">
											Monthly
										</option>
									</select>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Cannot be changed after being published
									</p>
									<p className="mt-2 text-sm text-red-600">{errors.frequency?.message}</p>
								</div>
							</div>
							{renderAllDay()}
							{!allDay && (
								<div className="col-span-full">
									<label
										htmlFor="durationInMinutes"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Duration In Minutes*
									</label>
									<div className="mt-2">
										<input
											{...register('durationInMinutes', {
												required: 'Duration In Minutes is required',
												min: 1,
												max: 1440,
											})}
											type="text"
											placeholder="5"
											className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
										/>
										<p
											className={
												errors.durationInMinutes?.message
													? 'mt-2 text-sm text-red-600'
													: 'hidden'
											}
											id="duration-in-minutes-error"
										>
											{errors.durationInMinutes?.message}
										</p>
									</div>
								</div>
							)}
							{renderFrequencyOn()}
							{renderTimesPerDay()}
							{renderAtTimes()}
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Options</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Select from a range of options including whether to feature or restrict content.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('isFeatured')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Featured
										</label>
										<p className="text-gray-500">Select if you want to feature this content</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">{renderIsPreset()}</div>
							<div className="col-span-full">{renderAutoAssign()}</div>
							<div className="col-span-full">{renderIsPublic()}</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('isMembersOnly')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Members Only
										</label>
										<p className="text-gray-500">Restrict content to users with an account</p>
									</div>
								</div>
							</div>
							<div>{renderIsRecommended()}</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							orgHabitId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						type="button"
						to="/habits"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					{isDirty || unmonitoredFieldChanged || isSubmitting ? (
						<button
							type="submit"
							disabled={
								orgHabitId
									? isSubmitting
									: !isDirty || isSubmitting || (!allDay && !selectedTimes.length) || !watchStartDate
							}
							className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
						>
							Save
						</button>
					) : orgHabit?.webUrl ? (
						<div
							onClick={() => window.open(orgHabit.webUrl)}
							className="cursor-pointer disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
						>
							{orgHabit?.status === 'draft' ? 'Preview' : 'Visit'}
						</div>
					) : (
						<div />
					)}
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Habit"
				body="This will delete the Habit for all Users and cannot be undone. Are you sure?"
				onConfirm={deleteOrgHabit}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(OrgHabitForm);
