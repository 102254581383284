import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import userStore from '../../stores/UserStore';
import {
	Metric,
	Text,
	BarList,
	// DonutChart,
	Grid,
	Card,
	Col,
	TabGroup,
	TabList,
	Tab,
	List,
	ListItem,
	ProgressBar,
	TabPanels,
	TabPanel,
	AreaChart,
} from '@tremor/react';
import _ from 'lodash';
import moment from 'moment';
import { capitalizeFirstLetter, getAhoyScreenLabel } from '../../utils/stringUtils';
import { useNavigate } from 'react-router-dom';

// const chartColors = ['violet', 'blue', 'blue', 'sky', 'cyan', 'slate', 'green', 'pink', 'orange', 'red'];

function Dashboard() {
	const [stats, setStats] = useState({});
	const [newUserCount, setNewUserCount] = useState(0);
	const [totalLeaders, setTotalLeaders] = useState(0);
	const [totalAdmins, setTotalAdmins] = useState(0);
	const [totalMembers, setTotalMembers] = useState(0);
	const [newGroupsCount, setNewGroupsCount] = useState(0);
	const [moodsCompletedCount, setMoodsCompletedCount] = useState(0);
	const [pendingInvitationsCount, setPendingInvitationsCount] = useState(0);
	const [pendingMemberInvitationsCount, setPendingMemberInvitationsCount] = useState(0);
	const [pendingLeaderInvitationsCount, setPendingLeaderInvitationsCount] = useState(0);
	const [pendingAdminInvitationsCount, setPendingAdminInvitationsCount] = useState(0);
	const [totalGroups, setTotalGroups] = useState(0);
	const [totalUsers, setTotalUsers] = useState(0);
	const [startedPracticesCount, setStartedPracticesCount] = useState(0);
	const [practicesCompletedCount, setPracticesCompletedCount] = useState(0);
	const [habitsCompletedCount, setHabitsCompletedCount] = useState(0);
	const [startedPathwaySectionsCount, setStartedPathwaySectionsCount] = useState(0);
	const [completedPathwaySectionsCount, setCompletedPathwaySectionsCount] = useState(0);
	const [postsCount, setPostsCount] = useState(0);
	const [prayerRequestsCount, setPrayerRequestsCount] = useState(0);
	const [completedAssessmentsCount, setCompletedAssessmentsCount] = useState(0);
	const [groupedTotalPosts, setGroupTotalPosts] = useState([]);
	const [groupedPathwaySectionsStarted, setGroupedPathwaySectionsStarted] = useState([]);
	const [groupedPathwaySectionsCompleted, setGroupedPathwaySectionsCompleted] = useState([]);
	const [groupedAssessmentsCompleted, setGroupedAssessmentsCompleted] = useState([]);
	const [groupedPrayerRequests, setGroupedPrayerRequests] = useState([]);
	const [groupedPracticesStarted, setGroupedPracticesStarted] = useState([]);
	const [groupedPracticesCompleted, setGroupedPracticesCompleted] = useState([]);
	const [groupedMoodsCreatedByType, setGroupedMoodsCreatedByType] = useState([]);
	const [groupedHabitsCompleted, setGroupedHabitsCompleted] = useState([]);
	const [groupedChallengesJoined, setGroupedChallengesJoined] = useState([]);
	const [groupedMoodsCreated, setGroupedMoodsCreated] = useState([]);
	const [rightTabIndex, setRightTabIndex] = useState(0);
	const [timeframe, setTimeframe] = useState(30);
	const [groupedGenders, setGroupedGenders] = useState([]);
	const [groupedAges, setGroupedAges] = useState([]);
	const [groupedAhoyEvents, setGroupedAhoyEvents] = useState([]);
	const [groupedAhoyEventsByName, setGroupedAhoyEventsByName] = useState([]);
	const [totalAhoyEvents, setTotalAhoyEvents] = useState([]);
	const [totalGroupedAhoyEvents, setTotalGroupedAhoyEvents] = useState([]);
	const navigate = useNavigate();
	const jwt = localStorage.getItem('jwt');

	useEffect(() => {
		if (!jwt) {
			navigate('/login');
			return;
		}
	}, []);

	useEffect(() => {
		if (jwt) {
			getAhoyEvents();
			getLeadersCount();
			getAdminsCount();
			getMembersCount();
			getTotalGroupsCount();
			getTotalUsersCount();
			// getTotalUsersLast5Weeks();
			getGroupedGendersCount();
			getGroupedAgesCount();
		}
	}, [orgsStore.currentOrg]);

	useEffect(() => {
		if (jwt) {
			getNewUsersCount();
			getPendingInvitationsCount();
			getNewGroupsCount();
			getStartedPracticesCount();
			getPracticesCompletedCount();
			getHabitsCompletedCount();
			getStartedPathwaySectionsCount();
			getCompletedPathwaySectionsCount();
			getPostsCount();
			getPrayerRequestsCount();
			getCompletedAssessmentsCount();
			getGroupedPostsCount();
			getGroupedPathwaySectionsStartedCount();
			getGroupedPathwaySectionsCompletedCount();
			getGroupedAssessmentsCount();
			getGroupedPrayerRequestsCount();
			getGroupedPracticesStarted();
			getGroupedPracticesCompleted();
			getHabitsCompleted();
			getChallengesJoined();
			getMoodsCompletedCount();
			getGroupedMoodsCount();
			// getChallengesJoinedCount();
		}
	}, [timeframe, orgsStore.currentOrg]);

	useEffect(() => {
		if (jwt) {
			handleRender();
		}
	}, [orgsStore.currentOrg]);

	useEffect(() => {
		getAhoyEvents();
	}, [userStore.user, timeframe]);

	const isBasic = orgsStore.getOrgTier() === 'basic' && !userStore.user?.isSuperUser;

	const timeframeDate = () => {
		if (timeframe == 0) {
			return orgsStore.currentOrg?.createdAt || '2022-01-01';
		} else {
			const createdDate = orgsStore.currentOrg?.createdAt;
			if (createdDate) {
				if (moment(createdDate).isAfter(moment().subtract(timeframe, 'days'))) {
					return createdDate;
				}
			}
			return moment().subtract(timeframe, 'days').utc().toISOString();
		}
	};

	const getAhoyEvents = async () => {
		if (orgsStore.currentOrg?.id) {
			const groupedEvents = await tetherApi.getAhoyGroupedCount({
				org_id: orgsStore.currentOrg.id,
				start_date: timeframeDate(),
				end_date: moment().utc().toISOString(),
			});
			const allEventsCount = await tetherApi.getAhoyMetricsCount({
				org_id: orgsStore.currentOrg.id,
				start_date: timeframeDate(),
				end_date: moment().utc().toISOString(),
			});
			const groupedEventsByName = await tetherApi.getAhoyGroupedCount({
				org_id: orgsStore.currentOrg.id,
				start_date: timeframeDate(),
				end_date: moment().utc().toISOString(),
				name: 'true',
			});
			if (timeframe === 0) {
				const allEventsCountByMonth = await tetherApi.getAhoyCountGroupedByMonth({
					org_id: orgsStore.currentOrg.id,
					end_date: moment().utc().toISOString(),
				});
				let ahoyByMonth = Object.keys(allEventsCountByMonth).map((key) => {
					return {
						date: moment(key).toISOString(),
						'Total Visits': allEventsCountByMonth[key],
					};
				});
				ahoyByMonth = ahoyByMonth.sort(function (a, b) {
					if (moment(a.date).isBefore(b.date)) {
						return -1;
					}
					return 1;
				});
				ahoyByMonth = ahoyByMonth.map((month) => {
					return {
						...month,
						date: moment(month.date).format('MMMM Do YYYY'),
					};
				});
				setTotalGroupedAhoyEvents(ahoyByMonth);
			} else {
				const allEventsCountByWeek = await tetherApi.getAhoyCountGroupedByWeek({
					org_id: orgsStore.currentOrg?.id,
					start_date: timeframeDate(),
					end_date: moment().utc().toISOString(),
				});

				let ahoyByWeek = Object.keys(allEventsCountByWeek).map((key) => {
					return {
						date: moment(key).toISOString(),
						'Total Visits': allEventsCountByWeek[key],
					};
				});
				ahoyByWeek = ahoyByWeek.sort(function (a, b) {
					if (moment(a.date).isBefore(b.date)) {
						return -1;
					}
					return 1;
				});
				ahoyByWeek = ahoyByWeek.map((week) => {
					return {
						...week,
						date: moment(week.date).format('MMMM Do YYYY'),
					};
				});
				setTotalGroupedAhoyEvents(ahoyByWeek);
			}
			setGroupedAhoyEvents(groupedEvents);
			setTotalAhoyEvents(allEventsCount);
			delete groupedEventsByName[''];
			setGroupedAhoyEventsByName(groupedEventsByName);
		} else if (userStore.user?.isSuperUser) {
			const groupedEvents = await tetherApi.getAhoyGroupedCount({
				global: true,
				start_date: timeframeDate(),
				end_date: moment().utc().toISOString(),
			});
			const groupedEventsByName = await tetherApi.getAhoyGroupedCount({
				global: true,
				start_date: timeframeDate(),
				end_date: moment().utc().toISOString(),
				name: 'true',
			});
			// console.log('groupedEventsByName', groupedEventsByName);
			const allEventsCount = await tetherApi.getAhoyMetricsCount({
				global: true,
				start_date: timeframeDate(),
				end_date: moment().utc().toISOString(),
			});
			if (timeframe === 0) {
				const allEventsCountByMonth = await tetherApi.getAhoyCountGroupedByMonth({
					global: true,
					end_date: moment().utc().toISOString(),
				});
				let ahoyByMonth = Object.keys(allEventsCountByMonth).map((key) => {
					return {
						date: moment(key).toISOString(),
						'Total Visits': allEventsCountByMonth[key],
					};
				});
				ahoyByMonth = ahoyByMonth.sort(function (a, b) {
					if (moment(a.date).isBefore(b.date)) {
						return -1;
					}
					return 1;
				});
				ahoyByMonth = ahoyByMonth.map((month) => {
					return {
						...month,
						date: moment(month.date).format('MMMM Do YYYY'),
					};
				});
				setTotalGroupedAhoyEvents(ahoyByMonth);
			} else {
				const allEventsCountByWeek = await tetherApi.getAhoyCountGroupedByWeek({
					global: true,
					start_date: timeframeDate(),
					end_date: moment().utc().toISOString(),
				});
				let ahoyByWeek = Object.keys(allEventsCountByWeek).map((key) => {
					return {
						date: moment(key).toISOString(),
						'Total Visits': allEventsCountByWeek[key],
					};
				});
				ahoyByWeek = ahoyByWeek.sort(function (a, b) {
					if (moment(a.date).isBefore(b.date)) {
						return -1;
					}
					return 1;
				});
				ahoyByWeek = ahoyByWeek.map((week) => {
					return {
						...week,
						date: moment(week.date).format('MMMM Do YYYY'),
					};
				});
				setTotalGroupedAhoyEvents(ahoyByWeek);
			}
			setGroupedAhoyEvents(groupedEvents);
			delete groupedEventsByName[''];
			setGroupedAhoyEventsByName(groupedEventsByName);
			setTotalAhoyEvents(allEventsCount);
		}
	};

	const getTotalUsersCount = async () => {
		const newCount = await tetherApi.newUserCountMetric();
		setTotalUsers(newCount);
	};

	const getNewUsersCount = async () => {
		const newCount = await tetherApi.newUserCountMetric(timeframeDate());
		setNewUserCount(newCount);
	};

	const getMoodsCompletedCount = async () => {
		const newCount = await tetherApi.moodsCompletedCountMetric(timeframeDate());
		setMoodsCompletedCount(newCount);
	};

	const getLeadersCount = async () => {
		const newCount = await tetherApi.totalUsersMetric('leader');
		setTotalLeaders(newCount);
	};

	const getAdminsCount = async () => {
		const newCount = await tetherApi.totalUsersMetric('admin');
		setTotalAdmins(newCount);
	};

	const getMembersCount = async () => {
		const newCount = await tetherApi.totalUsersMetric('member');
		setTotalMembers(newCount);
	};

	const getPendingInvitationsCount = async () => {
		const newCount = await tetherApi.pendingInvitationsMetric(timeframeDate());
		setPendingInvitationsCount(newCount);
		const memberCount = await tetherApi.pendingInvitationsMetric(timeframeDate(), 'member');
		setPendingMemberInvitationsCount(memberCount);
		const leaderCount = await tetherApi.pendingInvitationsMetric(timeframeDate(), 'leader');
		setPendingLeaderInvitationsCount(leaderCount);
		const adminCount = await tetherApi.pendingInvitationsMetric(timeframeDate(), 'admin');
		setPendingAdminInvitationsCount(adminCount);
	};

	const getTotalGroupsCount = async () => {
		const newCount = await tetherApi.newGroupsMetric();
		setTotalGroups(newCount);
	};

	const getGroupedGendersCount = async () => {
		const genders = await tetherApi.getGroupedGenders();
		setGroupedGenders(genders);
	};

	const getGroupedAgesCount = async () => {
		const ages = await tetherApi.getGroupedAges();
		setGroupedAges(ages);
	};

	const getNewGroupsCount = async () => {
		const newCount = await tetherApi.newGroupsMetric(timeframeDate());
		setNewGroupsCount(newCount);
	};

	const getStartedPracticesCount = async () => {
		const newCount = await tetherApi.startedPracticesMetric(timeframeDate());
		setStartedPracticesCount(newCount);
	};

	const getPracticesCompletedCount = async () => {
		const newCount = await tetherApi.completedPracticesMetric(timeframeDate());
		setPracticesCompletedCount(newCount);
	};

	const getHabitsCompletedCount = async () => {
		const newCount = await tetherApi.completedHabitsMetric(timeframeDate());
		setHabitsCompletedCount(newCount);
	};

	const getStartedPathwaySectionsCount = async () => {
		const newCount = await tetherApi.startedPathwaySectionsMetric(timeframeDate());
		setStartedPathwaySectionsCount(newCount);
	};

	const getCompletedPathwaySectionsCount = async () => {
		const newCount = await tetherApi.completedPathwaySectionsMetric(timeframeDate());
		setCompletedPathwaySectionsCount(newCount);
	};

	const getPostsCount = async () => {
		const newCount = await tetherApi.postsCreatedMetric(timeframeDate());
		setPostsCount(newCount);
	};

	const getPrayerRequestsCount = async () => {
		const newCount = await tetherApi.postsCreatedMetric(timeframeDate(), true);
		setPrayerRequestsCount(newCount);
	};

	const getCompletedAssessmentsCount = async () => {
		const newCount = await tetherApi.completedAssessmentsMetric(timeframeDate());
		setCompletedAssessmentsCount(newCount);
	};

	const getGroupedMoodsCount = async () => {
		const groupedData = await tetherApi.groupedMoodsCreatedMetric(
			timeframeDate(),
			timeframe === 0 ? 'month' : 'week',
		);
		const groupedDataByType = await tetherApi.groupedMoodsCreatedMetric(timeframeDate(), 'filterAttribute');
		setGroupedMoodsCreated(groupedData);
		setGroupedMoodsCreatedByType(groupedDataByType);
	};

	const getGroupedPostsCount = async () => {
		const groupedData = await tetherApi.groupedTotalPostsMetric(
			timeframeDate(),
			timeframe === 0 ? 'month' : 'week',
		);
		setGroupTotalPosts(groupedData);
	};

	const getGroupedPathwaySectionsStartedCount = async () => {
		const groupedData = await tetherApi.groupedGuideSectionsStarted(
			timeframeDate(),
			timeframe === 0 ? 'month' : 'week',
		);
		setGroupedPathwaySectionsStarted(groupedData);
	};

	const getGroupedPathwaySectionsCompletedCount = async () => {
		const groupedData = await tetherApi.groupedGuideSectionsCompleted(
			timeframeDate(),
			timeframe === 0 ? 'month' : 'week',
		);
		setGroupedPathwaySectionsCompleted(groupedData);
	};

	const getGroupedAssessmentsCount = async () => {
		const groupedData = await tetherApi.groupedAssessmentsCompleted(
			timeframeDate(),
			timeframe === 0 ? 'month' : 'week',
		);
		setGroupedAssessmentsCompleted(groupedData);
	};

	const getGroupedPrayerRequestsCount = async () => {
		const groupedData = await tetherApi.groupedPrayerRequests(timeframeDate(), timeframe === 0 ? 'month' : 'week');
		setGroupedPrayerRequests(groupedData);
	};

	const getGroupedPracticesStarted = async () => {
		const groupedData = await tetherApi.groupedPracticesStarted(
			timeframeDate(),
			timeframe === 0 ? 'month' : 'week',
		);
		setGroupedPracticesStarted(groupedData);
	};

	const getGroupedPracticesCompleted = async () => {
		const groupedData = await tetherApi.groupedPracticesCompleted(
			timeframeDate(),
			timeframe === 0 ? 'month' : 'week',
		);
		setGroupedPracticesCompleted(groupedData);
	};

	const getHabitsCompleted = async () => {
		const groupedData = await tetherApi.groupedHabitsCompleted(timeframeDate(), timeframe === 0 ? 'month' : 'week');
		setGroupedHabitsCompleted(groupedData);
	};

	const getChallengesJoined = async () => {
		const groupedData = await tetherApi.groupedChallengesJoined(
			timeframeDate(),
			timeframe === 0 ? 'month' : 'week',
		);
		setGroupedChallengesJoined(groupedData);
	};

	const handleRender = async () => {
		if (orgsStore.currentOrg || userStore.user?.isSuperUser) {
			fetchStats();
		} else {
			await userStore.fetchUser();
			await orgsStore.fetchOrgs();
		}
	};
	// const fetchStats = async () => {
	// 	const statsResponse = await tetherApi.getDashboard();

	// 	if (statsResponse?.data) {
	// 		setStats(statsResponse.data);
	// 	}
	// };

	const fetchStats = async () => {
		try {
			const statsResponse = await tetherApi.getDashboard();

			if (statsResponse && statsResponse.data) {
				setStats(statsResponse.data);
			} else {
				console.error('Invalid response from getDashboard:', statsResponse);
				// Optionally set default stats or show an error message
				setStats({});
			}
		} catch (error) {
			console.error('Error fetching dashboard stats:', error);
			// Optionally set default stats or show an error message
			setStats({});
		}
	};

	let usersData = [
		{ name: 'Members', value: totalMembers },
		{ name: 'Leaders', value: totalLeaders },
		{ name: 'Administrators', value: totalAdmins },
	];
	usersData = _.sortBy(usersData, 'value').reverse();
	let activityData = [
		{ name: 'Practices Started', value: startedPracticesCount },
		{ name: 'Practices Completed', value: practicesCompletedCount },
		{ name: 'Habits Completed', value: habitsCompletedCount },
		{ name: 'Plan Sections Started', value: startedPathwaySectionsCount },
		{ name: 'Plan Sections Completed', value: completedPathwaySectionsCount },
		{ name: 'New Posts', value: postsCount },
		// { name: 'New Prayer Requests', value: prayerRequestsCount },
		{ name: 'Surveys Completed', value: completedAssessmentsCount },
		{ name: 'Moods Shared', value: moodsCompletedCount },
		// { name: 'Challenges Joined', value: challengesJoinedCount },
	];
	activityData = _.sortBy(activityData, 'value').reverse();
	let invitesData = [
		{ name: 'Members', value: pendingMemberInvitationsCount },
		{ name: 'Leaders', value: pendingLeaderInvitationsCount },
		{ name: 'Administrators', value: pendingAdminInvitationsCount },
	];
	invitesData = _.sortBy(invitesData, 'value').reverse();
	const totalActivityEvents =
		startedPracticesCount +
		practicesCompletedCount +
		startedPathwaySectionsCount +
		completedPathwaySectionsCount +
		postsCount +
		prayerRequestsCount +
		moodsCompletedCount +
		completedAssessmentsCount +
		habitsCompletedCount;
	const dataFormatter = (number) => Intl.NumberFormat('us').format(number).toString();
	let groupedMetrics = [];
	groupedTotalPosts.forEach((postMetric) => {
		const formattedLabel = postMetric.label;
		const existingDateObjIndex = groupedMetrics.findIndex((met) => met.date === formattedLabel);
		if (existingDateObjIndex >= 0) {
			groupedMetrics[existingDateObjIndex]['Posts'] = postMetric.count;
		} else {
			groupedMetrics.push({
				date: formattedLabel,
				Posts: postMetric.count,
			});
		}
	});
	groupedPathwaySectionsStarted.forEach((sectionMetric) => {
		const formattedLabel = sectionMetric.label;
		const existingDateObjIndex = groupedMetrics.findIndex((met) => met.date === formattedLabel);
		if (existingDateObjIndex >= 0) {
			groupedMetrics[existingDateObjIndex]['Plan Sections Started'] = sectionMetric.count;
		} else {
			groupedMetrics.push({
				date: formattedLabel,
				Plans: sectionMetric.count,
			});
		}
	});
	groupedPathwaySectionsCompleted.forEach((sectionMetric) => {
		const formattedLabel = sectionMetric.label;
		const existingDateObjIndex = groupedMetrics.findIndex((met) => met.date === formattedLabel);
		if (existingDateObjIndex >= 0) {
			groupedMetrics[existingDateObjIndex]['Plan Sections Completed'] = sectionMetric.count;
		} else {
			groupedMetrics.push({
				date: formattedLabel,
				Plans: sectionMetric.count,
			});
		}
	});
	groupedAssessmentsCompleted.forEach((assessmentMetric) => {
		const formattedLabel = assessmentMetric.label;
		const existingDateObjIndex = groupedMetrics.findIndex((met) => met.date === formattedLabel);
		if (existingDateObjIndex >= 0) {
			groupedMetrics[existingDateObjIndex]['Surveys'] = assessmentMetric.count;
		} else {
			groupedMetrics.push({
				date: formattedLabel,
				Assessments: assessmentMetric.count,
			});
		}
	});
	groupedPrayerRequests.forEach((prayerRequestsMetric) => {
		const formattedLabel = prayerRequestsMetric.label;
		const existingDateObjIndex = groupedMetrics.findIndex((met) => met.date === formattedLabel);
		if (existingDateObjIndex >= 0) {
			groupedMetrics[existingDateObjIndex]['Prayers'] = prayerRequestsMetric.count;
		} else {
			groupedMetrics.push({
				date: formattedLabel,
				Prayers: prayerRequestsMetric.count,
			});
		}
	});
	groupedPracticesStarted.forEach((practiceStartedMetric) => {
		const formattedLabel = practiceStartedMetric.label;
		const existingDateObjIndex = groupedMetrics.findIndex((met) => met.date === formattedLabel);
		if (existingDateObjIndex >= 0) {
			groupedMetrics[existingDateObjIndex]['Practices Started'] = practiceStartedMetric.count;
		} else {
			groupedMetrics.push({
				date: formattedLabel,
				Practices: practiceStartedMetric.count,
			});
		}
	});
	groupedPracticesCompleted.forEach((practiceCompletedMetric) => {
		const formattedLabel = practiceCompletedMetric.label;
		const existingDateObjIndex = groupedMetrics.findIndex((met) => met.date === formattedLabel);
		if (existingDateObjIndex >= 0) {
			groupedMetrics[existingDateObjIndex]['Practices Completed'] = practiceCompletedMetric.count;
		} else {
			groupedMetrics.push({
				date: formattedLabel,
				Practices: practiceCompletedMetric.count,
			});
		}
	});
	groupedHabitsCompleted.forEach((habitsCompletedMetric) => {
		const formattedLabel = habitsCompletedMetric.label;
		const existingDateObjIndex = groupedMetrics.findIndex((met) => met.date === formattedLabel);
		if (existingDateObjIndex >= 0) {
			groupedMetrics[existingDateObjIndex]['Habits'] = habitsCompletedMetric.count;
		} else {
			groupedMetrics.push({
				date: formattedLabel,
				Habits: habitsCompletedMetric.count,
			});
		}
	});
	groupedChallengesJoined.forEach((challengesJoinedMetric) => {
		const formattedLabel = challengesJoinedMetric.label;
		const existingDateObjIndex = groupedMetrics.findIndex((met) => met.date === formattedLabel);
		if (existingDateObjIndex >= 0) {
			groupedMetrics[existingDateObjIndex]['Challenges'] = challengesJoinedMetric.count;
		} else {
			groupedMetrics.push({
				date: formattedLabel,
				Challenges: challengesJoinedMetric.count,
			});
		}
	});
	groupedMoodsCreated.forEach((moodCreatedMetric) => {
		const formattedLabel = moodCreatedMetric.label;
		const existingDateObjIndex = groupedMetrics.findIndex((met) => met.date === formattedLabel);
		if (existingDateObjIndex >= 0) {
			groupedMetrics[existingDateObjIndex]['Moods'] = moodCreatedMetric.count;
		} else {
			groupedMetrics.push({
				date: formattedLabel,
				Moods: moodCreatedMetric.count,
			});
		}
	});
	groupedMetrics = groupedMetrics.map((groupedMetric) => {
		if (groupedMetric['Posts'] === undefined) {
			groupedMetric['Posts'] = 0;
		}
		if (groupedMetric['Plan Sections Started'] === undefined) {
			groupedMetric['Plan Sections Started'] = 0;
		}
		if (groupedMetric['Plan Sections Completed'] === undefined) {
			groupedMetric['Plan Sections Completed'] = 0;
		}
		if (groupedMetric['Surveys'] === undefined) {
			groupedMetric['Surveys'] = 0;
		}
		if (groupedMetric['Practices'] === undefined) {
			groupedMetric['Practices'] = 0;
		}
		if (groupedMetric['Challenges'] === undefined) {
			groupedMetric['Challenges'] = 0;
		}
		if (groupedMetric['Prayers'] === undefined) {
			groupedMetric['Prayers'] = 0;
		}
		if (groupedMetric['Habits'] === undefined) {
			groupedMetric['Habits'] = 0;
		}
		if (groupedMetric['Challenges'] === undefined) {
			groupedMetric['Challenges'] = 0;
		}
		if (groupedMetric['Moods'] === undefined) {
			groupedMetric['Moods'] = 0;
		}
		if (groupedMetric['Practices Completed'] === undefined) {
			groupedMetric['Practices Completed'] = 0;
		}
		if (groupedMetric['Practices Started'] === undefined) {
			groupedMetric['Practices Started'] = 0;
		}
		groupedMetric['Total Activity'] =
			groupedMetric['Challenges'] +
			groupedMetric['Habits'] +
			groupedMetric['Moods'] +
			groupedMetric['Plan Sections Completed'] +
			groupedMetric['Plan Sections Started'] +
			groupedMetric['Posts'] +
			groupedMetric['Practices'] +
			groupedMetric['Practices Completed'] +
			groupedMetric['Practices Started'] +
			groupedMetric['Prayers'] +
			groupedMetric['Surveys'];
		return groupedMetric;
	});
	groupedMetrics = groupedMetrics.sort(function (a, b) {
		if (moment(a.date).isBefore(b.date)) {
			return -1;
		}
		return 1;
	});
	groupedMetrics.forEach((metric, index) => {
		groupedMetrics[index].date = moment(metric.date).format('MMMM Do YYYY');
		if (isNaN(metric['Total Activity'])) {
			groupedMetrics[index]['Total Activity'] = 0;
		}
	});

	let allow60 = false;
	let allow90 = false;
	if (!orgsStore.currentOrg?.createdAt) {
		allow60 = true;
		allow90 = true;
	} else {
		const createdDate = orgsStore.currentOrg?.createdAt;
		if (moment(createdDate).isBefore(moment().subtract(39, 'days'))) {
			allow60 = true;
		}
		if (moment(createdDate).isBefore(moment().subtract(69, 'days'))) {
			allow90 = true;
		}
	}

	const moodBarListData = [];
	groupedMoodsCreatedByType.forEach((moodData) => {
		const dataPiece = {
			name: capitalizeFirstLetter(moodData.label),
			value: moodData.count,
		};
		moodBarListData.push(dataPiece);
	});
	const possibleMoods = ['Awful', 'Bad', 'Okay', 'Good', 'Great'];
	possibleMoods.forEach((mood) => {
		if (!moodBarListData.some((listData) => listData.name === mood)) {
			moodBarListData.push({
				name: mood,
				value: 0,
			});
		}
	});

	let groupedAhoyData = [];
	Object.keys(groupedAhoyEvents).forEach((key) => {
		const label = getAhoyScreenLabel(key);
		groupedAhoyData.push({
			name: label,
			value: groupedAhoyEvents[key],
		});
	});
	groupedAhoyData = _.sortBy(groupedAhoyData, 'value').reverse();

	let groupedAhoyDataByName = [];
	Object.keys(groupedAhoyEventsByName).forEach((key) => {
		const name = key.charAt(0).toUpperCase() + key.substring(1);
		groupedAhoyDataByName.push({
			name,
			value: groupedAhoyEventsByName[key],
		});
	});
	groupedAhoyDataByName = _.sortBy(groupedAhoyDataByName, 'value').reverse();

	return (
		<div className="px-4 sm:px-6 lg:px-16 ">
			<div className="flex flex-col md:flex-row items-center" style={{ justifyContent: 'space-between' }}>
				<div className="flex flex-col md:flex-row items-center">
					<div className="flex flex-row items-center">
						<div>
							<h1 className="xs:pt-6 lg:pt-0 text-3xl pb-0.5 font-semibold text-black">
								Welcome, {userStore.user?.firstName}
							</h1>
							<div className="flex flew-row content-center">
								<h1 className="font-regular text-base text-gray-500 mr-2"></h1>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-row xs:pt-4 lg:pt-0">
					<div
						className={`flex flex-row align-center border border-gray-200 relative z-0 inline-flex shadow-sm rounded-lg -space-x-px ${
							isBasic ? '' : ''
						}`}
					>
						<a
							aria-current="day range"
							className={
								timeframe === 30
									? 'cursor-pointer rounded-l-lg z-10 bg-white text-black relative inline-flex items-center px-4 py-3 text-sm font-medium'
									: 'cursor-pointer rounded-l-lg bg-white text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-3 text-sm font-medium '
							}
							onClick={() => setTimeframe(30)}
						>
							30 days
						</a>
						<a
							aria-current="day range"
							className={
								timeframe === 60
									? 'cursor-pointer rounded-lg z-10 bg-white text-black relative inline-flex items-center px-4 py-3 text-sm font-medium'
									: `${
											allow60 ? 'cursor-pointer hover:bg-gray-50 ' : 'opacity-30 '
									  }bg-white  text-gray-500  relative inline-flex items-center px-4 py-3 text-sm font-medium `
							}
							onClick={() => {
								if (allow60) {
									setTimeframe(60);
								}
							}}
						>
							60 days
						</a>
						<a
							aria-current="day range"
							className={
								timeframe === 90
									? 'cursor-pointer rounded-lg z-10 bg-white text-black relative inline-flex items-center px-4 py-3 text-sm font-medium'
									: `${
											allow90 ? 'cursor-pointer hover:bg-gray-50 ' : 'opacity-30 '
									  }bg-white  text-gray-500  relative inline-flex items-center px-4 py-3 text-sm font-medium `
							}
							onClick={() => {
								if (allow90) {
									setTimeframe(90);
								}
							}}
						>
							90 days
						</a>
						<a
							aria-current="day range"
							className={
								timeframe === 0
									? 'cursor-pointer bg-white rounded-r-lg z-10 text-black relative inline-flex items-center px-4 py-3 text-sm font-medium'
									: 'cursor-pointer rounded-r-lg bg-white  text-gray-500  hover:bg-gray-50 relative inline-flex items-center px-4 py-3 text-sm font-medium '
							}
							onClick={() => setTimeframe(0)}
						>
							All time
						</a>
					</div>
				</div>
			</div>
			<Card className="mt-8 overflow-hidden p-0">
				<TabGroup defaultIndex={0}>
					<TabList
						className="space-x-0 bg-tremor-background-muted dark:bg-dark-tremor-background-muted"
						color="blue"
					>
						<Tab
							key={'Total Visits'}
							className="py-4 pl-5 pr-12 text-left ui-selected:bg-tremor-brand-inverted ui-focus-visible:ring-2 ui-focus-visible:ring-blue-400 ui-focus-visible:ring-offset-2 dark:ui-selected:bg-dark-tremor-background-muted"
						>
							<span className="block text-tremor-content dark:text-dark-tremor-content">
								{'Total Visits'}
							</span>
							<span className="mt-1 block text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
								{totalAhoyEvents.toLocaleString()}
							</span>
						</Tab>
						<Tab
							key={'Total Activity'}
							className="py-4 pl-5 pr-12 text-left ui-selected:bg-tremor-brand-inverted ui-focus-visible:ring-2 ui-focus-visible:ring-blue-400 ui-focus-visible:ring-offset-2 dark:ui-selected:bg-dark-tremor-background-muted"
						>
							<span className="block text-tremor-content dark:text-dark-tremor-content">
								{'Total Activity'}
							</span>
							<span className="mt-1 block text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
								{totalActivityEvents.toLocaleString()}
							</span>
						</Tab>
						<div
							className="border-r border-tremor-border dark:border-dark-tremor-border"
							aria-hidden={true}
						/>
					</TabList>
					<TabPanels>
						<TabPanel key={'Total Visits Panel'} className="p-6">
							<AreaChart
								data={totalGroupedAhoyEvents}
								index="date"
								categories={['Total Visits']}
								valueFormatter={(number) => `${Intl.NumberFormat('us').format(number).toString()}`}
								showGradient={false}
								showLegend={false}
								yAxisWidth={45}
								className="h-96 sm:block"
								colors={['blue']}
							/>
						</TabPanel>
						<TabPanel key={'Total Activity Panel'} className="p-6">
							<AreaChart
								data={groupedMetrics}
								index="date"
								categories={['Total Activity']}
								valueFormatter={(number) => `${Intl.NumberFormat('us').format(number).toString()}`}
								showGradient={false}
								showLegend={false}
								yAxisWidth={45}
								className="h-96 sm:block"
								colors={['blue']}
							/>
						</TabPanel>
					</TabPanels>
				</TabGroup>
			</Card>
			<Grid numItemsLg={1} className="gap-6 mt-6">
				<Col numColSpanLg={1}>
					<Card className="rounded-4xl h-full pt-8">
						{rightTabIndex === 0 ? (
							<div className="max-w-sm flex flex-row">
								<div className="w-1/3">
									<Text className="font-regular">All Visits</Text>
									<Metric className="font-semibold text-black">
										{totalAhoyEvents.toLocaleString()}
									</Metric>
								</div>
							</div>
						) : rightTabIndex === 1 ? (
							<div className="max-w-sm flex flex-row">
								<div className="w-1/3">
									<Text className="font-regular">All Visits</Text>
									<Metric className="font-semibold text-black">
										{totalAhoyEvents.toLocaleString()}
									</Metric>
								</div>
							</div>
						) : rightTabIndex === 2 ? (
							<div className="max-w-sm flex flex-row">
								<div className="w-1/3">
									<Text className="font-regular">All Activity</Text>
									<Metric className="font-semibold text-black">
										{totalActivityEvents.toLocaleString()}
									</Metric>
								</div>
							</div>
						) : rightTabIndex === 3 ? (
							<div className="max-w-sm flex flex-row">
								<div className="mr-2 w-1/3">
									<Text className="font-regular">Total Users</Text>
									<Metric className="font-semibold text-black">{totalUsers.toLocaleString()}</Metric>
								</div>
								<div className="w-1/3">
									<Text className="font-regular">New Users</Text>
									<Metric className="font-semibold text-black">
										{newUserCount.toLocaleString()}
									</Metric>
								</div>
							</div>
						) : rightTabIndex === 4 ? (
							<div className="max-w-sm flex flex-row">
								<div className="mr-2 w-1/3">
									<Text className="font-regular">Total Groups</Text>
									<Metric className="font-semibold text-black">{totalGroups.toLocaleString()}</Metric>
								</div>
								<div className="w-1/3">
									<Text className="font-regular">New Groups</Text>
									<Metric className="font-semibold text-black">
										{newGroupsCount.toLocaleString()}
									</Metric>
								</div>
							</div>
						) : rightTabIndex === 5 ? (
							<div className="max-w-sm flex flex-row">
								<div className="w-1/3">
									<Text className="font-regular">Pending Invites</Text>
									<Metric className="font-semibold text-black">
										{pendingInvitationsCount.toLocaleString()}
									</Metric>
								</div>
							</div>
						) : (
							<div className="max-w-sm flex flex-row">
								<div className="ml-2 w-1/3">
									<Text className="font-regular">Total Orgs</Text>
									<Metric className="font-semibold text-black">
										{stats.totalOrgs.toLocaleString()}
									</Metric>
								</div>
							</div>
						)}
						<TabGroup
							className="mt-8"
							onIndexChange={(newIndex) => {
								setRightTabIndex(newIndex);
							}}
						>
							<TabList className="mb-8" color="blue">
								<Tab className="font-regular">All Visits</Tab>
								<Tab className="font-regular">Top Content</Tab>
								<Tab className="font-regular">Top Activity</Tab>
								<Tab className="font-regular">Users</Tab>
								<Tab className="font-regular">Groups</Tab>
								<Tab className="font-regular">Invitations</Tab>
								{!orgsStore.currentOrg && userStore.user?.isSuperUser && (
									<Tab className="font-regular">Orgs</Tab>
								)}
							</TabList>
							<TabPanels>
								<TabPanel>
									<BarList
										data={groupedAhoyData}
										valueFormatter={dataFormatter}
										className="font-regular"
										color="blue"
									/>
								</TabPanel>
								<TabPanel>
									<BarList
										data={groupedAhoyDataByName}
										valueFormatter={dataFormatter}
										className="font-regular"
										color="blue"
									/>
								</TabPanel>
								<TabPanel>
									<BarList
										data={activityData}
										valueFormatter={dataFormatter}
										className="font-regular"
										color="blue"
									/>
								</TabPanel>
								<TabPanel>
									<BarList
										data={usersData}
										color="blue"
										valueFormatter={dataFormatter}
										className="font-regular"
									/>
								</TabPanel>
								<TabPanel>
									<BarList
										data={[{ name: 'Groups', value: totalGroups }]}
										valueFormatter={dataFormatter}
										className="font-regular"
										color="blue"
									/>
								</TabPanel>
								<TabPanel>
									<BarList
										data={invitesData}
										valueFormatter={dataFormatter}
										className="font-regular"
										color="blue"
									/>
								</TabPanel>
								{!orgsStore.currentOrg && userStore.user?.isSuperUser && <TabPanel></TabPanel>}
							</TabPanels>
						</TabGroup>
					</Card>
				</Col>
			</Grid>
			<Grid numItemsLg={2} className="gap-6 mt-6">
				<Col umColSpanLg={1}>
					<Card className="sm:mx-auto">
						<h3 className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
							Gender Overview
						</h3>
						<p className="mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content">
							Breakdown of your members by gender
						</p>
						<div className="mt-1 w-full">
							<List className="mt-4">
								{groupedGenders.slice(0, groupedGenders ? undefined : 5).map((sectionType) => (
									<ListItem key={sectionType.name}>
										<div className="w-full font-regular">
											<Text>{sectionType.name}</Text>
											<ProgressBar
												value={(sectionType.count / startedPathwaySectionsCount) * 100}
												label={sectionType.count}
												tooltip={sectionType.count}
												color="blue"
											/>
										</div>
									</ListItem>
								))}
							</List>
						</div>
					</Card>
				</Col>
				<Col umColSpanLg={1}>
					<Card className="sm:mx-auto">
						<h3 className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
							Age Overview
						</h3>
						<p className="mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content">
							Breakdown of your members by age
						</p>
						<div className="mt-1 w-full">
							<List className="mt-4">
								{groupedAges.slice(0, groupedAges ? undefined : 5).map((sectionType) => (
									<ListItem key={sectionType.name}>
										<div className="w-full font-regular">
											<Text>{sectionType.name}</Text>
											<ProgressBar
												value={(sectionType.count / startedPathwaySectionsCount) * 100}
												label={sectionType.count}
												tooltip={sectionType.count}
												color="blue"
											/>
										</div>
									</ListItem>
								))}
							</List>
						</div>
					</Card>
				</Col>
			</Grid>
		</div>
	);
}

export default observer(Dashboard);
