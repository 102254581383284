import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import tetherApi from '../api/tether';
import orgsStore from '../stores/OrgsStore';
import userStore from '../stores/UserStore';
import { Alert } from './Alert';

const CreateOrgPage = () => {
	const {
		register,
		handleSubmit,
		setValue,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({});

	const navigate = useNavigate();

	useEffect(() => {
		handleRender();
	}, []);

	const handleRender = async () => {
		if (userStore.getJwt()) {
			await userStore.fetchUser();
			await orgsStore.fetchOrgs();

			if (userStore.user && userStore.user.orgs.length > 0) {
				navigate('/');
			}
		} else {
			navigate('/login');
		}
	};

	const onSubmit = async () => {
		// Retrieve data this way to include image
		const formData = new FormData(document.getElementById('org-form'));

		const result = await tetherApi.signUpOrg(formData);

		if (result) {
			await userStore.fetchUser();
			await orgsStore.fetchOrgs();

			navigate('/dashboard');
		}
	};

	return (
		<div className="min-h-full h-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-neutral-25 ">
			<Alert />

			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<img
					className="mx-auto h-3.5 w-auto"
					src={
						window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
							? './tether-white-wordmark.svg'
							: './tether-wordmark-black.svg'
					}
					alt="Tether"
				/>
				<h2 className="mt-10 text-center xs:text-3xl text-4xl font-semibold tracking-tight text-black-900  ">
					Create your workspace
				</h2>
				<p className={'mt-3 text-center text-base font-medium text-gray-700 '}>No credit card required</p>
			</div>

			<div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="py-8 px-4 sm:rounded-lg sm:px-10">
					<form className="space-y-6" id="org-form" onSubmit={handleSubmit(onSubmit)}>
						<div>
							<label htmlFor="name" className="block text-base font-medium text-gray-900  mb-2">
								Name*
							</label>
							<div className="mt-1">
								<input
									{...register('name', { required: true })}
									type="text"
									autoComplete="organization"
									placeholder="Organization Name"
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6"
									onChange={(event) => {
										const text = event.target.value;
										const fixedText = text
											.toLowerCase()
											.replace(/[^A-Za-z0-9\s]/g, '')
											.replaceAll(' ', '-');
										setValue('subdomain', fixedText);
									}}
								/>
								<p className={errors.name ? 'mt-2 text-sm text-red-600' : 'hidden'} id="name-error">
									{errors.name && errors.name.type === 'required' && 'Name is required'}
								</p>
							</div>
						</div>
						<div>
							<label htmlFor="subdomain" className="block text-base font-medium text-gray-900  mb-2">
								Subdomain*
							</label>
							<div className="mt-1">
								<input
									{...register('subdomain', { required: true })}
									type="text"
									autoComplete="organization"
									placeholder="Subdomain"
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6"
								/>
								<p
									className={errors.subdomain ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="subdomain-error"
								>
									{errors.subdomain &&
										errors.subdomain.type === 'required' &&
										'Subdomain is required'}
								</p>
							</div>
						</div>
						<div>
							<label htmlFor="url" className="block text-base font-medium text-gray-900  mb-2">
								Website*
							</label>
							<div className="mt-1">
								<input
									{...register('url', { required: true })}
									autoComplete="url"
									placeholder="https://"
									className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6"
								/>
								<p className={errors.url ? 'mt-2 text-sm text-red-600' : 'hidden'} id="url-error">
									{errors.url && errors.url.type === 'required' && 'Website is required'}
								</p>
							</div>
						</div>
						<div>
							<label htmlFor="image" className="block text-base font-medium text-gray-900  mb-2">
								Logo
							</label>
							{/* <div className="mt-1 sm:mt-0 sm:col-span-2">
								<input
									{...register('image')}
									type="file"
									accept=".png,.jpg,.jpeg"
									className="appearance-none block w-full px-3 py-3 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 sm:text-sm   bg-neutral-100 font-regular"
								/>
								<p className="mt-2 text-sm text-gray-500  font-regular">
									Accepts .png or .jpg
								</p>
								<p className={errors.image ? 'mt-2 text-sm text-red-600' : 'hidden'} id="image-error">
									{errors.image && errors.image.type === 'required' && 'Logo is required'}
								</p>
							</div> */}
							<div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
								<div className="text-center">
									<div className="mt-4 flex text-sm leading-6 text-gray-600">
										<label
											htmlFor="file-upload"
											className="relative cursor-pointer rounded-md bg-white font-semibold text-zinc-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-zinc-600 focus-within:ring-offset-2 hover:text-zinc-500"
										>
											<span>Upload a file</span>
											<input
												id="file-upload"
												{...register('image')}
												name="file-upload"
												type="file"
												className="sr-only"
											/>
										</label>
										<p className="pl-1">or drag and drop</p>
									</div>
									<p className="mt-2 text-sm text-gray-500  font-regular">Accepts .png or .jpg</p>
									<p
										className={errors.image ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="image-error"
									>
										{errors.image && errors.image.type === 'required' && 'Logo is required'}
									</p>
								</div>
							</div>
						</div>
						{/* <div>
							<label
								htmlFor="signupSpecifiedRole"
								className="block text-base font-medium text-gray-700  mb-2"
							>
								Your Role*
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<select
									{...register('signupSpecifiedRole', { required: false })}
									className="appearance-none block w-full px-3 py-3 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 sm:text-sm font-regular  bg-neutral-100  border-none"
									placeholder="Role at Organization"
								>
									<option key="role-church-staff" value="Church Staff">
										Staff Member
									</option>
									<option key="role-pastor" value="Pastor">
										Executive Team
									</option>
									<option key="role-non-staff" value="Non-Staff">
										Non-Staff
									</option>
									<option key="role-organization-leader" value="Organization Leader">
										Volunteer
									</option>
									<option key="role-other" value="Other">
										Other
									</option>
								</select>
							</div>
						</div> */}
						{/* <div>
							<label
								htmlFor="weeklyAttendance"
								className="block text-base font-medium text-gray-700  mb-2"
							>
								Organization Size?
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<select
									{...register('weeklyAttendance', { required: false })}
									className="appearance-none block w-full px-3 py-3 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 sm:text-sm font-regular  bg-neutral-100  border-none"
									placeholder="250"
								>
									<option key="weekly-attendance-0-250" value="250">
										0-250
									</option>
									<option key="weekly-attendance-251-350" value="350">
										251-350
									</option>
									<option key="weekly-attendance-351-500" value="500">
										351-500
									</option>
									<option key="weekly-attendance-501-750" value="750">
										501-750
									</option>
									<option key="weekly-attendance-751-1250" value="1250">
										751-1250
									</option>
									<option key="weekly-attendance-1251-2000" value="2000">
										1,251-2,000
									</option>
									<option key="weekly-attendance-2001-5000" value="5000">
										2,001-5,000
									</option>
									<option key="weekly-attendance-over-5000" value="5001">
										Over 5000
									</option>
								</select>
							</div>
							<p className="mt-2 text-sm text-gray-500 ">
								Weekly attendance size is the total number of people online or in-person who attend your
								service.
							</p>
						</div> */}
						{/* <div>
							<label
								htmlFor="tier"
								className="block text-base font-medium text-gray-700  mb-2"
							>
								Tier of Tether*
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<select
									{...register('tier', { required: false })}
									className="appearance-none block w-full px-3 py-3 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 sm:text-sm font-regular  bg-neutral-100  border-none"
									placeholder="Tether for Organizations"
								>
									<option key="tier-tether-pro" value="pro">
										Pro
									</option>
									<option key="tier-tether-basic" value="basic">
										Essential
									</option>
								</select>
							</div>
						</div> */}

						<div>
							<button
								type="submit"
								disabled={!isDirty || isSubmitting}
								className="disabled:opacity-25 flex w-full justify-center rounded-md bg-zinc-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
							>
								Start 14-day free trial
							</button>
						</div>
					</form>
				</div>
			</div>
			<div className="mt-4">
				<p className="mt-2 text-center text-sm text-gray-600  font-regular">
					Need help?{' '}
					<a href="mailto:support@withtether.com" className="font-medium text-zinc-600 hover:text-zinc-500">
						Contact support
					</a>
				</p>
			</div>
		</div>
	);
};

export default observer(CreateOrgPage);
