import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';
import UserSearch from '../users/UserSearch';
import Pagination from '../Pagination';

function ChannelForm() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({});

	const { channelId } = useParams();
	const [channel, setChannel] = useState(null);
	const [users, setUsers] = useState(null);
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [userSearchOpen, setUserSearchOpen] = useState(false);

	let navigate = useNavigate();

	useEffect(() => {
		if (channelId && !channel && orgsStore.orgs.length) {
			fetchChannel(channelId);
		}

		if (channel) {
			fetchUsers();
		}

		setSuccessMessage(channelId ? 'Channel updated!' : 'Channel created!');
	}, [channel, page, orgsStore.currentOrg, deleteConfirm, userSearchOpen]);

	const fetchChannel = async (channelId) => {
		const channelResponse = await tetherApi.getChannel(channelId);

		setChannel(channelResponse.data);

		reset(channelResponse.data);
	};

	const fetchUsers = async () => {
		const usersResponse = await tetherApi.getUsers(page, perPage, {
			channelId: channelId,
		});

		setUsers(usersResponse.data);

		const totalCount = usersResponse.total;
		const pagesCount = Math.ceil(totalCount / perPage);

		setTotal(totalCount);
		setNumPages(pagesCount);
		setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
		setEndIndex(perPage * (page - 1) + usersResponse.data.length);
	};

	const handleUserAdd = (event) => {
		event.preventDefault();

		setUserSearchOpen(true);
	};

	const removeUser = async (userId) => {
		const result = await tetherApi.removeUserFromChannel(channelId, userId);

		if (result) {
			alertService.success('User removed');

			fetchUsers();
		}
	};

	const addUser = async (event) => {
		event.preventDefault();

		const userId = event.target.dataset.userId;

		const result = await tetherApi.addUserToChannel(channelId, userId);

		if (result) {
			setUserSearchOpen(false);

			alertService.success('User added');

			fetchUsers();
		}
	};

	const onSubmit = async (data) => {
		const result = await submitChannel(data);

		if (result) {
			alertService.success(successMessage);

			navigate('/channels');
		}
	};

	const submitChannel = async (data) => {
		const formData = new FormData(document.getElementById('channel-form'));

		formData.append('isDefault', data.isDefault);

		if (data.id) {
			return await tetherApi.updateChannel(data.id, formData);
		} else {
			return await tetherApi.createChannel(formData);
		}
	};

	const deleteChannel = async () => {
		const result = await tetherApi.deleteChannel(channelId);

		if (result) {
			alertService.success('Channel deleted');

			navigate('/channels');
		}
	};

	return (
		<form className="p-10" id="channel-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="mb-6 text-3xl tracking-tight font-semibold text-gray-900 ">
							{channelId ? 'Edit' : 'New'} Channel
						</h3>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the group name, description, and image.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
									Name
								</label>
								<div className="mt-2">
									<input
										{...register('title', { required: 'Title is required' })}
										type="text"
										className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md  "
									/>
									<p
										className={errors.title?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="title-error"
									>
										{errors.title?.message}
									</p>
								</div>
							</div>

							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('isDefault')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											All Group Admins
										</label>
										<p className="text-gray-500">
											If checked, all staff and group admins will automatically be added to this
											Channel
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							channelId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						type="button"
						to="/channels"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>

			{!!channelId && (
				<>
					<div className={channelId ? '' : 'hidden'}>
						<div className="pt-8">
							<div className="sm:flex sm:items-center">
								<div className="sm:flex-auto mt-4">
									<h1 className="text-2xl font-semibold text-black">Members</h1>
								</div>
								<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
									{!channel?.isDefault && (
										<Link
											to="/"
											className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
											onClick={handleUserAdd}
										>
											New Member
										</Link>
									)}
								</div>
							</div>
						</div>

						<div className="">
							<div className="mt-8 flow-root">
								<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
									<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
										<table className="min-w-full divide-y divide-gray-30">
											<thead className="bg-white">
												<tr>
													<th
														scope="col"
														className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
													>
														First Name
													</th>
													<th
														scope="col"
														className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
													>
														Last Name
													</th>
													<th
														scope="col"
														className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
													>
														Email
													</th>
													<th
														scope="col"
														className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
													>
														Phone
													</th>
													{!channel?.isDefault && (
														<th
															scope="col"
															className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
														>
															Edit
														</th>
													)}
												</tr>
											</thead>
											<tbody className="bg-white">
												{users?.map((user, i) => (
													<tr key={user.id} className={i % 2 === 0 ? undefined : 'bg-white'}>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate">
															{user.firstName}
														</td>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate">
															{user.lastName}
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-pointer">
															{user.email}
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-pointer">
															{user.phone}
														</td>
														<td className="relative py-4 text-sm font-medium">
															{!channel?.isDefault && (
																<button
																	type="button"
																	onClick={() => {
																		removeUser(user.id);
																	}}
																	className="mr-3 justify-center py-1 px-2 border border-transparent text-sm font-medium rounded-md text-red-800 bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
																>
																	Remove
																</button>
															)}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Pagination
						page={page}
						setPage={setPage}
						numPages={numPages}
						startIndex={startIndex}
						endIndex={endIndex}
						total={total}
					/>
				</>
			)}

			<UserSearch
				isOpen={userSearchOpen}
				channelId={channelId}
				actionLabel="Add"
				onActionClick={addUser}
				onCancel={() => {
					setUserSearchOpen(false);
				}}
				roles="admin,leader"
			/>
			<Confirm
				isOpen={deleteConfirm}
				title="Delete Channel"
				body="This will delete all data for the Channel and cannot be undone. Are you sure?"
				onConfirm={deleteChannel}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(ChannelForm);
