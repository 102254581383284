import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import orgsStore from '../../stores/OrgsStore';
import { observer } from 'mobx-react-lite';
import tetherApi from '../../api/tether';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Metric, Text, Grid, Card, Col } from '@tremor/react';
import { Link } from 'react-router-dom';

function CourseStats() {
	const { courseId } = useParams();
	const [course, setCourse] = useState(null);
	const [courseViews, setCourseViews] = useState(0);
	const [courseSubscriptions, setCourseSubscriptions] = useState(0);
	const [courseVisits, setCourseVisits] = useState(0);
	const [courseCompletions, setCourseCompletions] = useState(0);
	const [userCourseStats, setUserCourseStats] = useState([]);
	// const [page, setPage] = useState(1);
	// const [perPage] = useState(25);
	// const [total, setTotal] = useState(0);
	// const [numPages, setNumPages] = useState(1);
	// const [startIndex, setStartIndex] = useState(0);
	// const [endIndex, setEndIndex] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			if (courseId) {
				const pw = await fetchCourse(courseId);
				await fetchCourseViews(courseId);
				await fetchTotalSubscriptions(courseId);
				await fetchTotalCompletions(pw);
				await fetchCourseVisits(courseId);
				await fetchUserCourseStats(courseId);
			}
		};
		fetchData();
	}, [courseId, orgsStore.currentOrg]);

	const fetchCourse = async (courseId) => {
		const courseResponse = await tetherApi.getCourse(courseId);

		setCourse(courseResponse.data);
		return courseResponse.data;
	};

	const fetchCourseViews = async (courseId) => {
		const viewsResponse = await tetherApi.getCourseViewsCount(courseId);
		setCourseViews(viewsResponse ?? 0);
	};

	const fetchCourseVisits = async (courseId) => {
		if (orgsStore.currentOrg?.id) {
			const viewsResponse = await tetherApi.getAhoyMetricsCount({
				org_id: orgsStore.currentOrg?.id,
				course_id: courseId,
				screen_name: 'course_overview',
			});
			setCourseVisits(viewsResponse ?? 0);
		}
	};

	const fetchTotalSubscriptions = async (courseId) => {
		const subscriptionsResponse = await tetherApi.getTotalCourseSubscriptions(courseId);
		setCourseSubscriptions(subscriptionsResponse ?? 0);
	};

	const fetchUserCourseStats = async (courseId) => {
		const userCourseStatsResponse = await tetherApi.getUserCourseStats(courseId);
		setUserCourseStats(userCourseStatsResponse);
	};

	const fetchTotalCompletions = async (pw) => {
		const completionsResponse = await tetherApi.getTotalCourseCompletions(
			pw.id,
			moment(pw.createdAt).format('YYYY-MM-DD'),
		);
		setCourseCompletions(completionsResponse ?? 0);
	};

	if (!course) {
		return <div></div>;
	}

	return (
		<div className="px-8">
			<div className="flex flex-row pt-8">
				{course?.imageUrl ? (
					<img
						src={course.secondaryImageUrl ?? course.imageUrl}
						className="w-24 h-24 rounded-lg object-cover"
					/>
				) : (
					<div className="w-24 h-24 rounded-lg bg-gray-300"></div>
				)}
				<div className="pl-8 pt-4">
					<p className="text-2xl pb-.5 font-semibold text-gray-900">{course.name}</p>
					<p className="text-base font-regular text-gray-500 ">{`Published ${moment(course.createdAt).format(
						'MMMM Do, YYYY',
					)} at ${moment(course.createdAt).format('h:mma')}`}</p>
				</div>
			</div>
			<Grid numItemsLg={8} className="gap-6 mt-6">
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Starts</Text>
								<Metric className="font-medium">{courseSubscriptions}</Metric>
							</div>
						</div>
					</Card>
				</Col>
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Visits</Text>
								<Metric className="font-medium">{courseVisits}</Metric>
							</div>
						</div>
					</Card>
				</Col>
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Completions</Text>
								<Metric className="font-medium">{courseCompletions}</Metric>
							</div>
						</div>
					</Card>
				</Col>
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Lessons Started</Text>
								<Metric className="font-medium">{courseViews}</Metric>
							</div>
						</div>
					</Card>
				</Col>
			</Grid>
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto">
						<div className="inline-block min-w-full py-2 align-middle">
							<table className="min-w-full divide-y divide-gray-300">
								<thead>
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
										>
											Name
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Progress
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
											<span className="sr-only">Edit</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{userCourseStats.map((userWithStats, index) => {
										const { user, completedUserCourseSectionsCount, totalCourseSectionsCount } =
											userWithStats;
										return (
											<tr key={`${user.email}-${index}`}>
												<td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
													<div className="flex items-center">
														{/* <div className="h-11 w-11 flex-shrink-0">
															<img
																alt=""
																src={user.image}
																className="h-11 w-11 rounded-full"
															/>
														</div> */}
														<div className="">
															<div className="font-medium text-gray-900">{`${user.firstName} ${user.lastName}`}</div>
															<div className="mt-1 text-gray-500">{user.email}</div>
														</div>
													</div>
												</td>
												<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 min-w-72">
													<div className="font-medium text-gray-900">
														{completedUserCourseSectionsCount ?? 0} /{' '}
														{totalCourseSectionsCount ?? 0} Lessons Completed
													</div>
													<div aria-hidden="true" className="mt-3">
														<div className="overflow-hidden rounded-full bg-gray-200">
															<div
																style={{
																	width: `${
																		(completedUserCourseSectionsCount /
																			totalCourseSectionsCount) *
																		100
																	}%`,
																}}
																className="h-2 rounded-full bg-blue-600"
															/>
														</div>
													</div>
												</td>
												<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
													{user.role}
												</td>
												<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/users/${user.id}`}
														className="font-medium text-neutral-800 hover:text-neutral-900"
													>
														View
													</Link>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default observer(CourseStats);
