import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pagination from '../Pagination';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
// import IntroductionCard from '../IntroductionCard';

function AssessmentsList() {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [assessments, setAssessments] = useState([]);
	const [sortBy, setSortBy] = useState('title');
	const [sortOrder, setSortOrder] = useState('asc');

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchAssessments();
		}
	}, [page, orgsStore.currentOrg]);

	useEffect(() => {
		fetchAssessments();
	}, [sortBy, sortOrder]);

	const fetchAssessments = async () => {
		const opts = {};
		if (sortBy) {
			opts.sortBy = sortBy;
		}
		if (sortOrder) {
			opts.sortOrder = sortOrder;
		}
		const assessmentsResponse = await tetherApi.getAssessments(page, perPage, opts);

		setAssessments(assessmentsResponse.data);

		const totalCount = assessmentsResponse.total;
		const pagesCount = Math.ceil(totalCount / perPage);

		setTotal(totalCount);
		setNumPages(pagesCount);
		setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
		setEndIndex(perPage * (page - 1) + assessmentsResponse.data.length);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-2xl font-semibold text-black xs:pt-6 xs:pb-4 lg:pt-0 lg:pb-0">Surveys</h1>
					{/* <h3 className="font-regular text-gray-500">Surveys that provide actionable insights</h3> */}
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="https://support.withtether.com/en/articles/8993283-surveys-on-tether"
						target="_blank"
						className="mr-3 inline-flex items-center justify-center rounded-lg border bg-white px-4 py-2 text-sm font-medium shadow-sm"
					>
						Learn More
					</Link>
					<Link
						to="/assessments/new"
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
					>
						New Survey
					</Link>
				</div>
			</div>
			{/* <IntroductionCard
				localStorageKey="hideAssessmentVideo"
				vimeoLink="https://player.vimeo.com/video/858736945?h=6accfedad2"
				bullets={[
					'Common use cases include spiritual growth check-ins, group or ministry reviews, and surveys that gauge specific needs or interests.',
					'Assessments are anonymous to encourage honest feedback, so if you want to encourage 2-way customization, edit the completion message and include a contact link of some kind.',
					// eslint-disable-next-line quotes
					"Make sure you're encouraging reflection that's beneficial for them—not just beneficial for your organization.",
				]}
				type="Surveys"
			/> */}
			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							{assessments.length === 0 ? (
								<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
									<div className="text-center">
										<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
											No surveys to display
										</p>
										<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
											No surveys are available.
										</p>
										<Link
											to="/assessments/new"
											className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
										>
											New Survey
										</Link>
									</div>
								</div>
							) : (
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'title') {
														setSortBy('title');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Name
													{sortBy === 'title' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'subtitle') {
														setSortBy('subtitle');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Description
													{sortBy === 'subtitle' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Start Date
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												End Date
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'updated_at') {
														setSortBy('updated_at');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Last Updated
													{sortBy === 'updated_at' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Stats
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Edit
											</th>
											{/* <th scope="col" className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
											<span className="sr-only">Stats</span>
											<span className="sr-only">Edit</span>
										</th> */}
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{assessments.map((assessment, assessmentIdx) => (
											<tr
												key={assessment.id}
												className={assessmentIdx % 2 === 0 ? undefined : 'bg-white'}
											>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
													{assessment.title}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{assessment.subtitle}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{moment(assessment.startDate).format('LL')}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{assessment.endDate ? moment(assessment.endDate).format('LL') : ''}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{moment(assessment.updatedAt).format('LL')}
												</td>
												<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/assessments/${assessment.id}/assessment_periods`}
														className="font-medium text-neutral-800 hover:text-neutral-900"
													>
														Stats
														<span className="sr-only">, {assessment.name}</span>
													</Link>
												</td>
												<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/assessments/${assessment.id}`}
														className="font-medium text-neutral-800 hover:text-neutral-900m"
													>
														Edit
														<span className="sr-only">, {assessment.name}</span>
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>
		</div>
	);
}

export default observer(AssessmentsList);
